// topology of categories
export default
[
    {
      "category":"fees",
      "parent":""
    },
    {
      "category": "transfer",
      "parent": ""
    },
    {
      "category":"late_payment",
      "parent":"fees"
    },
    {
      "category":"foreign_transaction_fee",
      "parent":"fees"
    },
    {
      "category":"food",
      "parent":""
    },
    {
      "category":"restaurants",
      "parent":"food"
    },
    {
      "category":"fast_food",
      "parent":"food"
    },
    {
      "category":"groceries",
      "parent":"food"
    },
    {
      "category":"coffee_shop",
      "parent":"food"
    },
    {
      "category":"school_food",
      "parent":"food"
    },
    {
      "category":"kids",
      "parent":""
    },
    {
      "category":"daycare_preschool",
      "parent":"kids"
    },
    {
      "category":"toys",
      "parent":"kids"
    },
    {
      "category":"education",
      "parent":""
    },
    {
      "category":"tuition",
      "parent":"education"
    },
    {
      "category":"shopping",
      "parent":""
    },
    {
      "category":"clothing",
      "parent":"shopping"
    },
    {
      "category":"music",
      "parent":"shopping"
    },
    {
      "category":"books",
      "parent":"shopping"
    },
    {
      "category":"cosmetics",
      "parent":"shopping"
    },
    {
      "category":"gifts",
      "parent":"shopping"
    },
    {
      "category":"sport_outdoor_shopping",
      "parent":"shopping"
    },
    {
        "category":"hotel",
        "parent":"travel"
    },
    {
      "category":"subscription",
      "parent":""
    },
    {
      "category":"entertainment",
      "parent":""
    },
    {
      "category":"movies",
      "parent":"entertainment"
    },
    {
      "category":"parks",
      "parent":"entertainment"
    },
    {
      "category":"auto",
      "parent":""
    },
    {
      "category":"gas",
      "parent":"auto"
    },
    {
      "category":"parking",
      "parent":"auto"
    },
    {
      "category":"tolls",
      "parent":"auto"
    },
    {
        "category":"carwash",
        "parent":"auto"
    },
    {
        "category":"car_maintenance",
        "parent":"auto"
    },
    {
      "category":"travel",
      "parent":""
    },
    {
      "category":"taxi",
      "parent":"travel"
    },
    {
      "category":"air_travel",
      "parent":"travel"
    },
    {
      "category":"personal_care",
      "parent":""
    },
    {
      "category":"hair",
      "parent":"personal_care"
    },
    {
      "category":"health",
      "parent":""
    },
    {
      "category":"doctor",
      "parent":"health"
    },
    {
      "category":"pharmacy",
      "parent":"health"
    },
    {
      "category":"home",
      "parent":""
    },
    {
      "category":"mortgage",
      "parent":"home"
    },
    {
      "category":"hoa",
      "parent":"home"
    },
    {
      "category":"furniture",
      "parent":"home"
    },
    {
      "category":"business",
      "parent":""
    },
    {
      "category":"pay_card",
      "parent":""
    },
    {
      "category":"bills_utilities",
      "parent":""
    },
    {
      "category":"phone",
      "parent":"bills_utilities"
    },
    {
      "category":"uncategorized",
      "parent":""
    },
    {
      "category": "education",
      "parent":""
    }
  ];